import React from "react";
import ShopContext from "../ShopContext";
import Button from "../../../atoms/Button/Button";

import "./CartInline.less";
import { MIN_CART_VALUE } from "../../../../const/cart";

const CartInline = props => {
  const cart = React.useContext(ShopContext);

  let totalQuantity = 0;
  let totalPrice = 0;
  if (cart.products) {
    cart.products.forEach(x => {
      totalQuantity = totalQuantity + x.quantity || 0;
      totalPrice = totalPrice + x.quantity * x.price;
    });
  }

  if (!cart) return null;

  const classNames = ["cart-inline"];

  return (
    <div className={classNames.join(" ")}>
      <div className="cart-inline__right">
        {totalPrice < MIN_CART_VALUE ? (
          <div className="cart-inline__notice">
            Mindestbestellwert noch nicht erreicht
          </div>
        ) : (
          <div className="cart-inline__price">
            {new Intl.NumberFormat(undefined, {
              style: "currency",
              currency: "EUR"
            }).format(totalPrice)}
            <div className="cart-inline__price-legal">
              inkl. Versand und MwSt.
            </div>
          </div>
        )}
        <Button onClick={cart.onBuy} disabled={totalPrice < MIN_CART_VALUE}>
          zur Kasse
        </Button>
      </div>
    </div>
  );
};

export default CartInline;
