import React from "react";
import PropTypes from "prop-types";
import ShopContext from "../ShopContext";

import "./CartProduct.less";

const CartProduct = props => {
  const cart = React.useContext(ShopContext);
  let item = cart.products.find(x => x.id === props.id);
  if (!item) {
    item = {
      id: props.id,
      name: props.name,
      price: props.price,
      quantity: 0
    };
  }

  const onIncrease = () => {
    if (item.quantity < 10) {
      cart.onChange(
        props.id,
        props.name,
        props.sku,
        props.price,
        item.quantity + 1
      );
    }
  };

  const onDecrease = () => {
    if (item.quantity > 0) {
      cart.onChange(
        props.id,
        props.name,
        props.sku,
        props.price,
        item.quantity - 1
      );
    }
  };

  let cnMinus = ["cart-product__operation"];
  let cnPlus = ["cart-product__operation"];

  return (
    <div className="cart-product">
      <div
        role="button"
        tabIndex="-1"
        onClick={onDecrease}
        onKeyDown={onDecrease}
        className={cnMinus.join(" ")}
      >
        <div>-</div>
      </div>
      <div className="cart-product__quantity-group">
        <div className="cart-product__quantity">{item.quantity}</div>
        <div className="cart-product__multiplier">Stck.</div>
      </div>
      <div
        role="button"
        tabIndex="-1"
        onClick={onIncrease}
        onKeyUp={onIncrease}
        className={cnPlus.join(" ")}
      >
        +
      </div>
    </div>
  );
};

CartProduct.protoTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  sku: PropTypes.string.isRequired
};

export default CartProduct;
