import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import CartProduct from "./CartProduct/CartProduct";

import "./Shop.less";

const ShopTool = props => {
  return (
    <div className="shop">
      {props.products.map(product => {
        let classNames = ["product"];
        if (!product.isAvailable) {
          classNames.push("product--sold-out");
        }

        return (
          <div key={product.title} className={classNames.join(" ")}>
            {!product.isAvailable && (
              <div className="product__sold-out">AUSVERKAUFT!</div>
            )}
            <div>
              <Img
                fluid={product.image}
                alt={product.imageTitle}
                title={product.imageTitle}
                className="product__image"
              />
              <h3 className="product__title">{product.title}</h3>
              {product.html && (
                <div dangerouslySetInnerHTML={{ __html: product.html }} />
              )}
            </div>
            {product.isAvailable && (
              <div className="product__footer">
                <div className="product__cart">
                  {/* <div className="product__cart-title">Deine Auswahl:</div> */}
                  <CartProduct
                    id={product.title}
                    name={product.imageTitle}
                    price={product.price}
                    sku={product.sku}
                  />
                </div>
                <div className="product__details">
                  <div className="product__price">
                    <span className="product__price-per">je</span>
                    {new Intl.NumberFormat(undefined, {
                      style: "currency",
                      currency: "EUR"
                    }).format(product.price)}
                  </div>
                  <div className="product__quantity">
                    {product.quantity} g
                    <span className="product__quantity-details">
                      (
                      {new Intl.NumberFormat(undefined, {
                        style: "currency",
                        currency: "EUR"
                      }).format(
                        Math.floor((product.price / product.quantity) * 1000) /
                          10
                      )}
                      / 100 g)
                    </span>
                    <br />
                    inkl. MwSt.
                  </div>
                </div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

ShopTool.protoTypes = {
  products: PropTypes.shape({
    title: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    imageTitle: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired,
    price: PropTypes.number.isRequired,
    isAvailable: PropTypes.bool.isRequired,
    sku: PropTypes.string.isRequired,
    html: PropTypes.string.isRequired
  })
};

const Shop = () => {
  const data = useStaticQuery(graphql`
    {
      allFile(
        filter: {
          sourceInstanceName: { eq: "products" }
          extension: { eq: "md" }
        }
      ) {
        edges {
          node {
            childMarkdownRemark {
              frontmatter {
                title
                image {
                  childImageSharp {
                    fluid(maxWidth: 500) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                imageTitle
                quantity
                price
                isAvailable
                sku
                skuTest
              }
              html
            }
          }
        }
      }
    }
  `);

  const products = data.allFile.edges
    .map(product => ({
      ...product.node.childMarkdownRemark.frontmatter,
      image:
        product.node.childMarkdownRemark.frontmatter.image.childImageSharp
          .fluid,
      sku:
        process.env.GATSBY_ENV !== "production"
          ? product.node.childMarkdownRemark.frontmatter.skuTest
          : product.node.childMarkdownRemark.frontmatter.sku,
      html: product.node.childMarkdownRemark.html
    }))
    .sort((a, b) => {
      if (!a.isAvailable) {
        return 1;
      }
      if (!b.isAvailable) {
        return -1;
      }
      return 0;
    });
  return <ShopTool products={products} />;
};

export default Shop;
