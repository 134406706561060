import React from "react";

import Layout from "../components/organisms/Layout/Layout";
import SEO from "../components/atoms/SEO/SEO";
import Section from "../components/organisms/Section/Section";
import PageWidth from "../components/organisms/PageWidth/PageWidth";
import FAQ from "../components/templates/FAQ/FAQ";
import Video from "../components/atoms/Video/Video";
import OgImage from "../images/og/sorten.jpg";
import Shop from "../components/organisms/Shop/Shop";
import CartPage from "../components/organisms/Shop/CartPage/CartPage";
import CartInline from "../components/organisms/Shop/CartInline/CartInline";
import { MIN_CART_VALUE } from "../const/cart";

const Sorten = () => (
  <>
    <CartPage />
    <Layout>
      <SEO
        title="Honig aus Hamburg und Magdeburg"
        description="Mein Honig kommt aus einem ruhigen Familienstadtteil mit vielen Gärten."
        image={OgImage}
      />
      <Section>
        <PageWidth>
          <h1>Honig aus Magdeburg</h1>
          <p className="text-max-line">
            Mein Honig kommt aus dem Hopfengarten in Magdeburg. Ein ruhiger
            Familienstadtteil mit vielen Gärten. Da ich nur etwa 6 Völker habe,
            gibt es teilweise pro Sorte nur wenige Gläser pro Jahr.
          </p>
          <h2>Diese Sorten habe ich</h2>
          <Video
            src="Honig-Sorten-im-Vergleich"
            title="Honig Sorten im Vergleich"
            className="mobile-full-width"
          />
          <p className="text-max-line">
            Alle Honige sind <em>vegetarisch</em>, <em>laktosefrei</em>,{" "}
            <em>glutenfrei</em>, <em>nussfrei</em> und <em>alkoholfrei</em>.
          </p>
          <p className="text-max-line"></p>
        </PageWidth>
      </Section>
      <Section>
        <PageWidth>
          <Shop />
          <CartInline />
          <p className="small-print text-max-line">
            Da Honig ein Lebensmittel ist, ist die Rückgabe ausgeschlossen!
          </p>
          <p className="small-print text-max-line">
            Da Honig ein Naturprodukt ist, können die gelieferten Honige in
            Farbe und Konsistenz von den Bildern abweichen. Der gelieferte Honig
            ist mit einem Etikett versehen.
          </p>
          <p className="small-print text-max-line">
            Versand ist inklusive. Ich versende ab {MIN_CART_VALUE}€
            Einkaufswert (2 Gläsern); allerdings nur innerhalb Deutschlands.
            10,7% MwSt. inklusive.
          </p>
        </PageWidth>
      </Section>
      <Section>
        <PageWidth>
          <FAQ
            title={
              <>
                Häufige
                <br />
                Fragen:
              </>
            }
            questions={[
              {
                title: "Wie lange ist der Honig haltbar?",
                text:
                  "Honig ist tatsächlich sehr, sehr lange haltbar. Bei guter Lagerung wird ein fester Honig sicher noch in 50 Jahren genießbar sein. Dennoch verlangt der Gesetzgeber, dass ich eine Haltbarkeit festlege. Je nach Honigsorte ist das zwischen 6 Monaten und 5 Jahren. Für meine Mischungen aus HONIG+WACHS, die auch im Kühlschrank gelagert werden sollten, gebe ich eine Haltbarkeit zwischen wenigen Wochen und 3 Monaten an.",
              },
              {
                title:
                  "Ich mag flüssigen/festen Honig. Welchen soll ich bestellen?",
                text:
                  "Honig kristallisiert (verfestigt) von ganz allein. Je geringer der Wasseranteil, desto schneller geht es. Wenn Du einen flüssigen Honig willst, solltest Du Obstblüte oder Robinie probieren. Die bleiben recht lange flüssig. Wenn Du festen Honig möchtest, wähle am Besten Linde oder Edelkastanie (die frischen Ernten sind hier aber auch noch flüssig).",
              },
              {
                title: "Kann ich Honig wieder flüssig machen?",
                text:
                  "Ja. Einfach in ein warmes (aber nicht kochendes) Wasserbad stellen. Der Honig verflüssigt sich dann.",
              },
              {
                delimeter: true,
                title: "Abwicklung & Zahlung",
              },
              {
                title:
                  "Ich wohne in Hamburg/Magdeburg, kann ich den Honig auch abholen?",
                text:
                  "Ja, das kannst Du. Bitte melde Dich aber vorher bei mir, weil ich den Honig aus meiner Wohnung in Hamburg verschicke. In Magdeburg sind nicht immer alle Sorten verfügbar, die hier angezeigt werden.",
              },
              {
                title: "Warum muss ich mindestens zwei Gläser bestellen?",
                text:
                  "Die Nebenkosten für eine Bestellung sind leider nicht unerheblich. Wenn Du mehr als ein Glas hast, reichen aber auch Deine Vorräte länger oder Du kannst Jemandem eine Freude machen!",
              },
              {
                title: "Wie groß ist die nachhaltigste Bestellmenge?",
                text:
                  "4 Gläser. Ich habe kleine Kartons, die auf maximal 4 Gläser ausgerichtet sind. Größere Bestellungen werden mit wiederverwendeten Kartons verschickt. Bitte verwende auch Du Karton und Packpapier wieder, wenn möglich.",
              },
              {
                title:
                  "Was passiert mit meinen Bank- oder Kartendaten, wenn ich sie eingebe?",
                text: (
                  <>
                    Alle Zahlungen werden von{" "}
                    <a href="http://www.stripe.com" target="blank">
                      Stripe
                    </a>{" "}
                    durchgeführt. Ich habe keinen Zuriff auf eure Bank- oder
                    Kredit&shy;karten&shy;daten, die liegen sicher auf den
                    Stripe-Servern. Und auch nur solange gesetzlich
                    vorgeschrieben.
                  </>
                ),
              },
              {
                title: "Warum kann ich nicht mit PayPal zahlen?",
                text:
                  "PayPal ist sehr angenehm für Käufer. Leider nicht so sehr für Verkäufer. In der Vergangenheit hat PayPal schon Konten einfach gesperrt, wenn es meinte, der Eigentümer verstoße gegen den Willen des Unternehmens. So ein Verhalten möchte ich nicht unterstützen. Es gibt hier aber andere zeitgemäße Zahlungsmethoden.",
              },
            ]}
          />
        </PageWidth>
      </Section>
    </Layout>
  </>
);

export default Sorten;
